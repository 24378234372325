import React from "react";
import image from "../img/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineCheck } from "react-icons/ai";
import { Button } from "@material-tailwind/react";
import Payment from '../img/payment.png'

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer aria-label="Site Footer" className="bg-[#181A32] bg-chart">
      <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
        <div className="lg:flex lg:items-start lg:gap-8">
          <div className="mt-8 grid grid-cols-1 gap-8 lg:mt-0 lg:grid-cols-3 lg:gap-y-16">
            <div className="col-span-5 lg:col-span-1">
              <img src={image} width={500} />
              <div>
                <h2 className="text-2xl font-bold text-white">About Us</h2>

                <p className="mt-4 text-white">
                  I'm Flavio Mata Alvarez, CEO of King United Roofing, a locally owned and operated company
                </p>
                <Button
                  variant="gradient"
                  size="sm"
                  className="hidden lg:inline-block my-4"
                  color="red"
                  onClick={() => navigate("/about")}
                >
                  <span>Read More</span>
                </Button> 
              </div>
            </div>

            <div className="col-span-4 sm:col-span-1">
              <p className="font-bold text-2xl text-white">Services</p>

              <nav aria-label="Footer Navigation - Services" className="mt-6">
                <ul className="space-y-4 text-sm">
                  <li>
                    <Link
                      to={"/services"}
                      className="text-white flex items-center flex items-center transition hover:opacity-75"
                    >
                      <AiOutlineCheck className="mr-4 font-bold text-lg" />
                      ROOFING
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={"/services"}
                      className="text-white flex items-center transition hover:opacity-75"
                    >
                      <AiOutlineCheck className="mr-4 font-bold text-lg" />
                      GUTTER
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={"/services"}
                      className="text-white flex items-center transition hover:opacity-75"
                    >
                      <AiOutlineCheck className="mr-4 font-bold text-lg" />
                      SIDING
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={"/services"}
                      className="text-white flex items-center transition hover:opacity-75"
                    >
                      <AiOutlineCheck className="mr-4 font-bold text-lg" />
                      WHAT IS EPDM?
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={"/services"}
                      className="text-white flex items-center transition hover:opacity-75"
                    >
                      <AiOutlineCheck className="mr-4 font-bold text-lg" />
                      TPO ROOFING SERVICES
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/services"}
                      className="text-white flex items-center transition hover:opacity-75"
                    >
                      <AiOutlineCheck className="mr-4 font-bold text-lg" />
                      OTHER SERVICES
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>

            <div className="col-span-5 sm:col-span-1">
              <p className="font-medium text-2xl text-white">Contact Info</p>

              <nav aria-label="Footer Navigation - Company" className="mt-6">
                <ul className="space-y-4 text-sm">
                  <li>
                    <a
                      href="tel:+1(252) 294-7620"
                      className="text-white transition hover:opacity-75"
                    >
                      (252) 294-7620
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="tel:+1(252) 525-0985"
                      className="text-white transition hover:opacity-75"
                    >
                      (252) 525-0985
                    </a>
                  </li> */}
                  <li>
                    <a
                      href="mailto:kingunitedroofing@gmailcom"
                      className="text-white transition hover:opacity-75"
                    >
                      kingunitedroofing@gmailcom
                    </a>
                  </li>
                  <li>
                    <p
                      
                      className="text-white transition "
                    >
                      <span className="font-bold">Locations in</span> Greenville North Carolina and surrounding areas
                    </p>
                  </li>
                  <li>
                    <p
                      
                      className="text-white transition "
                    >
                      <span className="font-bold">Miles Around: </span>150 miles
                    </p>
                  </li>
                  {/* <li>
                    <p
                      className="text-white transition hover:opacity-75"
                    >
                      <span>Miles around: 50 Miles</span>
                    </p>
                  </li> */}
                </ul>
              </nav>
            </div>

            <div className="col-span-2 sm:col-span-1">
              <p className="font-medium text-2xl text-white">Business Hours</p>

              <nav aria-label="Footer Navigation - Company" className="mt-6">
                <ul className="space-y-4 text-sm">
                  <li>
                    <p className="text-white transition hover:opacity-75">
                      8 am to 5 pm <br /> 
                    </p>
                  </li>
                  <li>
                    <p className="font-medium text-2xl text-white">Payment Methods</p>
                    <nav aria-label="Footer Navigation - Company" className="mt-6">
                      <ul className="space-y-4 text-sm">
                        <li>
                          <p className="text-white transition hover:opacity-75">
                          Check, Cash
                          </p>
                        </li>
                      </ul>
                    </nav>
                    <img src={Payment} width={120} alt="" />
                  </li>
                  <li className="pt-12">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3340894.313019288!2d-82.5015778185598!3d35.141851308162316!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88541fc4fc381a81%3A0xad3f30f5e922ae19!2sCarolina%20del%20Norte%2C%20EE.%20UU.!5e0!3m2!1ses-419!2sni!4v1694115098094!5m2!1ses-419!2sni"
                      width="280"
                      height="250"
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </li>
                </ul>
              </nav>
             
            </div>

            {/* <div className="col-span-2 sm:col-span-1">
              <p className="font-medium text-white">Payment Methods</p>

              <nav aria-label="Footer Navigation - Legal" className="mt-6">
                <ul className="space-y-4 text-sm">
                  <li className="text-white transition hover:opacity-75">
                  Venmo, Paypal,Zelle, Cash App
                  </li>
                  <li className="flex">
                    <img className="mx-2" width={35} src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c5/Square_Cash_app_logo.svg/1200px-Square_Cash_app_logo.svg.png" alt="" />
                    <img className="mx-2" width={35} src="https://www.nicepng.com/png/full/433-4338249_its-a-logo-of-a-hand-on-the.png" alt="" />
                    <img className="mx-2" width={35} src="https://cdn-icons-png.flaticon.com/512/2676/2676682.png" alt="" />
                  </li>
                </ul>
              </nav>
            </div> */}

            <ul className="col-span-2 flex justify-start gap-6 lg:col-span-5 lg:justify-end">
              {/* <li>
                <a
                  href="https://www.facebook.com/2011superduty/"
                  rel="noreferrer"
                  target="_blank"
                  className="text-white transition hover:opacity-75"
                >
                  <img
                    width="30px"
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Facebook_Logo_%282019%29.png/1024px-Facebook_Logo_%282019%29.png"
                    alt=""
                    srcset=""
                  />
                </a>
              </li> */}
            </ul>
          </div>
        </div>

        <div className="mt-8 border-t border-white pt-8">
          <div className="sm:flex sm:justify-between">
            <p className="text-xs text-white">
              &copy; 2023. King United Roofing. All rights reserved.
            </p>

            <nav
              aria-label="Footer Navigation - Support"
              className="mt-8 sm:mt-0"
            >
              <ul className="flex flex-wrap justify-start gap-4 text-xs lg:justify-end">
                <li>
                  <a
                    href="https://mediasolutionsonline.com/"
                    target="_blank"
                    className="text-white transition hover:opacity-75"
                  >
                    Developed by MSO
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
