import { Typography } from "@material-tailwind/react";
import React from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import { AiFillCheckCircle } from "react-icons/ai";
import WhyChooseUs from "../components/WhyChooseUs";
import ComponentFr from "../components/ComponentFr";
import { Link } from "react-router-dom";
import Img1 from "../img/portfolio/13.jpg";
import Img2 from "../img/portfolio/15.jpg";
import Img3 from "../img/portfolio/7.jpg";
import Img4 from "../img/portfolio/9.jpg";

export const About = () => {
  
  return (
    <div>
      <div className="header-title px-24 py-32">
        <Link to={'/'} className="text-[25px] text-white">Home</Link>
        <h2 className="font-bold text-[60px] text-white">About Us</h2>
      </div>
      <div
        data-aos="fade-up"
        data-aos-anchor-placement="top-center"
        className="grid lg:grid-cols-2 grid-cols-1 gap-4 py-24 lg:px-24 px-4"
      >
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 lg:py-36 lg:py-12">
          <img className="m-2" src={Img1} alt="" srcset="" />
          <img className="m-2" src={Img2} alt="" srcset="" />
          <img className="m-2" src={Img3} alt="" srcset="" />
          <img className="m-2" src={Img4} alt="" srcset="" />
        </div>
        <div className="flex flex-col lg:ml-8 justify-center">
          <h2 className="text-[50px] text-gray-900 font-bold">
            We Have 15 years Of Experience
          </h2>
          <Typography className="text-gray-700 font-medium text-justify">
            <span className="text-[35px] text-red-700 font-bold">
              About Us
            </span>
            <br />
            A locally owned and operated company. We have been involved in the roofing industry for most of our lives and this has taught us that to ensure a good reputation, complete honesty, reliability and meticulous attention to detail are paramount. We also have a team of dedicated professionals who will provide you with the quality of service par excellence. They apply the highest level of professionalism from the beginning of our clients' projects, from concept to completion. You can call us and contact the best customer service
(252) 294-7620 

          </Typography>
          {/* <Tabs className="mt-8 z-0" id="custom-animation" value="whychooseus">
            <TabsHeader>
              {data.map(({ label, value }) => (
                <Tab key={value} value={value}>
                  {label}
                </Tab>
              ))}
            </TabsHeader>
            <TabsBody
              animate={{
                initial: { y: 250 },
                mount: { y: 0 },
                unmount: { y: 250 },
              }}
              color="green"
            >
              {data.map(({ value, desc }) => (
                <TabPanel className="text-justify" key={value} value={value}>
                  {desc}
                </TabPanel>
              ))}
            </TabsBody>
          </Tabs> */}
        </div>
      </div>     
      <WhyChooseUs/>


      {/* <ComponentFr/> */}

    </div>
  );
};
