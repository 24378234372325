import React from "react";
import { AiFillCheckCircle } from "react-icons/ai";

const WhyChooseUs = () => {
  return (
    <div>
      <div className="grid lg:grid-cols-3 grid-cols-1 gap-4 py-4 lg:px-24">
        <div className="bg-gray-800 p-8 text-white">
          <AiFillCheckCircle className="text-4xl" />
          <br />
          <h2 className="text-2xl font-bold">Mission</h2>
          <br />
          <p>
          Incomparable experience and attentive professionalism.
          We consider it a privilege to be in this business and we love our work.
          Shared expertise, fully licensed and certified, and we're waiting to help you with all your roofing needs.

          </p>
        </div>
        <div className="bg-gray-800 p-8 text-white">
          <AiFillCheckCircle className="text-4xl" />
          <br />
          <h2 className="text-2xl font-bold">Vision</h2>
          <br />
          <p>
          That is beyond expectations.
          We provide fast service, results of the highest quality.
          We are and will continue to be leaders, successful, efficient and focused on quality because we believe in the power of achieving our goals.

          </p>
        </div>
        <div className="bg-gray-800 p-8 text-white">
          <AiFillCheckCircle className="text-4xl" />
          <br />
          <h2 className="text-2xl font-bold">Why choose us?</h2>
          <br />
          <p>
          AFFORDABLE - SUSTAINABILITY - TRANSPARENCY!
            EXCELLENCE IS OUR VANGUARD!
              Authorized, guaranteed and insured.
        Maintaining our integrity in each project.
        We have the experience to provide you with the guarantee of a quality installation.
        Give yourself and your business peace of mind knowing you can trust it!
        We guarantee exceptional craftsmanship and on-time completion.

          </p>
        </div>
      </div>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 lg:px-32 p-12">
        <div className="lg:text-right text-center px-8">
            <span className="font-bold text-gray-700"> DEDICATION -QUALITY- EXCELLENT SERVICE</span>
            <h2 className="font-bold text-4xl text-gray-900">OUR COMMITMENT TO YOUR HOME IS OUR PROMISE!</h2>
        </div>
        <div className="px-8 flex lg:text-normal text-center items-center lg:border-l-2">
            <p className="text-xl">King United Roofing is Located in North Carolina, Call us at (252) 294-7620 </p>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
