import React, { useEffect } from "react";
import "./App.css";
import { Outlet } from "react-router-dom";
import Header from "./components/Header";
import { AiOutlineEnvironment, AiOutlineClockCircle } from "react-icons/ai";
import Logo from "./img/logo.png";
import Footer from "./components/Footer";
import Map from "./components/Map";
import Aos from 'aos';
import 'aos/dist/aos.css'

function App() {
  useEffect(() => {
    Aos.init();
  }, [])
  
  return (
    <div>
      <div id="WAButton">
        <div className="btn-whatsapp">
            <a href="https://wa.me/12522947620" target="_blank">
                <img src="https://static.vecteezy.com/system/resources/previews/022/101/124/original/whatsapp-logo-transparent-free-png.png" alt="Whatsapp"/>
            </a>
        </div>
    </div>

    <div id="WAButton">
        <div className="btn-phone">
            <a href="tel:+1(252) 294-7620" target="_blank">
                <img width="80px" src="https://cdn-icons-png.flaticon.com/512/6596/6596115.png" alt="Whatsapp"/>
            </a>
        </div>
    </div>
      <marquee className="bg-[#003090] py-2 px-12 lg:block hidden">
        <em className="text-white"> King United Roofing | </em>
        <i className="fa fa-flag-o"></i>{" "}
        <span className="text-white">
          {" "}
          Kentucky, North Carolina |{" "}
        </span>
        <i className="fa fa-flag-o"></i>{" "}
        <span className="text-white"> 15 years of Experience | </span>{" "}
        <i className="fa fa-flag-o"></i>{" "}
        <span className="text-white"> kingunitedroofing@gmailcom | </span>
        <i className="fa fa-flag-o"></i>{" "}
        <span className="text-white"> Free Estimates | </span>
      </marquee>
      <Header className="w-full" />
      
      <main>
        <Outlet />
      </main>
      {/* <div className="fixed bottom-20 right-0 left-icon">
        <a className="" href="https://www.facebook.com/2011superduty/" target="_blank">
          <div className="bg-[#E1E402] p-2 rounded-bl-lg flex">
            <img
              width="30px"  
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Facebook_Logo_%282019%29.png/1024px-Facebook_Logo_%282019%29.png"
            />
            <img
            className="logo-icon ml-4"
            width="100px"
            src="https://logohistory.net/wp-content/uploads/2022/10/Facebook-Logo.png"
          />
          </div>
        </a>
      </div> */}
      <Footer />
    </div>
  );
}

export default App;
